// eslint-disable-next-line import/no-relative-parent-imports, import/no-unassigned-import
import '../scss/main.scss';

// needed for positioning by bootstrap dropdowns, popover and tooltip module
// eslint-disable-next-line import/no-unassigned-import
import '@popperjs/core';

import { SiteHelper } from './modules/sitehelper';
import { CatalogBoxComponent } from './modules/catalog-box';
import { SuggestController } from './modules/solr-suggest';
import { SearchController } from './modules/solr-ajaxified';

import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import { GoogleMapsController } from './modules/googlemaps';

import { debounce } from './modules/debounce';

// eslint-disable-next-line import/no-unassigned-import, @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap';

declare global {
    interface Window {
        siteHelper: SiteHelper,
        catalogBoxComponent: CatalogBoxComponent,
        searchController: SearchController,
        googleMapsController: GoogleMapsController,
        googleMapsLoader: GoogleMapsLoader,
        isInitialized: boolean,
        dataLayer: object[],
        ucDps: UsercentricsDataProcessingServices,
        sha256Hash:(value: string) => Promise<string>
    }
}

window.bootstrap = bootstrap;
window.isInitialized = false;

window.sha256Hash = async function sha256Hash (value: string): Promise<string> {
    const data = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
};

const labels:L10nList = {
    de: {
        orderCatalog: 'Bestellen',
        searchUrl: '/suche/',
        suggestionsResultTitle: 'Suchvorschläge',
        documentsResultTitle: 'Themen & Angebote'
    },
    fr: {
        orderCatalog: 'Commander',
        searchUrl: '/fr/recherche/',
        suggestionsResultTitle: 'Suggestion de recherche',
        documentsResultTitle: 'Thèmes et Offres'
    }
};

// dynamic loading
async function promoBoxComponentLoader (): Promise<void> {
    const promoBoxes = document.querySelectorAll('.js-promocopy');
    if (promoBoxes.length > 0) {
        const module = await import(/* webpackChunkName: "promobox" */ './modules/promo-box');
        module.promoBoxComponent().handleCopyClicks('.js-promocopy');
    }
}

async function fcSuggestLoader (): Promise<void> {
    const fcSuggests = document.querySelectorAll('.js-fcsuggest');
    if (fcSuggests.length > 0) {
        const module = await import(/* webpackChunkName: "fcsuggest" */ './modules/fcibe-suggest');
        [].forEach.call(fcSuggests, function (elem) {
            module.fcBookingEngineSuggest().init(elem, { whiteLabel: 'kuoni' });
        });
    }
}

async function fastbookingLoader (lang: string): Promise<void> {
    const fastbookingForms = document.querySelectorAll('.tx-knfastbooking form');
    if (fastbookingForms.length > 0) {
        const module = await import(/* webpackChunkName: "fastbooking" */ './modules/fastbooking');
        [].forEach.call(fastbookingForms, function (formElem) {
            module.fastbookingComponent().init(formElem, { }, lang);
        });
    }
}

async function destinationSliderNavLoader (lang: string): Promise<void> {
    const destinationSliderNavContainer = document.getElementById('destinations');
    if (destinationSliderNavContainer) {
        const module = await import(/* webpackChunkName: "destnav" */ './modules/destination-nav');
        module.destinationSliderNav().init(destinationSliderNavContainer, lang);
    }
}

async function crmFormsComponentLoader (): Promise<void> {
    const crmConsultingForm = document.getElementById('crmConsultingForm');
    if (crmConsultingForm) {
        const module = await import(/* webpackChunkName: "crmforms" */ './modules/crmforms');
        module.crmFormsComponent().init(crmConsultingForm);
    }
}

async function lightBoxLoader (): Promise<void> {
    const lightBoxGalleries = document.querySelectorAll('.img-gallery a');
    if (lightBoxGalleries.length > 0) {
        await import(/* webpackChunkName: "lightbox" */ './modules/lightbox');
    }
}

async function splideLoader (): Promise<void> {
    const splideElems = document.getElementsByClassName('splide');
    if (splideElems.length > 0) {
        await import(/* webpackChunkName: "splide" */ './modules/splide');
    }
}

function workOnDom (): void {
    window.siteHelper = new SiteHelper();
    window.siteHelper.handleAlertBar('alertBar', 'alertBarCloseLink');
    window.siteHelper.handleSearchBarFocus('collapseSearchBar', 'headerSearchInput');
    const lang = window.siteHelper.getLanguage();

    // dynamic modules
    promoBoxComponentLoader().then(() => null).catch(() => null);
    fcSuggestLoader().then(() => null).catch(() => null);
    fastbookingLoader(lang).then(() => null).catch(() => null);
    crmFormsComponentLoader().then(() => null).catch(() => null);
    lightBoxLoader().then(() => null).catch(() => null);

    // search suggest
    const searchElems = document.getElementsByClassName('tx-solr-search-form');
    [].forEach.call(searchElems, function (el) {
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        const sc = new SuggestController(el, labels[lang].searchUrl,
            {
                suggestionsResultTitle: labels[lang].suggestionsResultTitle,
                documentsResultTitle: labels[lang].documentsResultTitle
            });
    });

    // search in header: handle empty search
    const headerSearchForm = <HTMLFormElement>document.getElementById('header-search');
    if (headerSearchForm) {
        headerSearchForm.addEventListener('submit', (event) => {
            const searchInput = <HTMLInputElement>headerSearchForm.querySelector('#headerSearchInput');
            if (searchInput && searchInput.value === '') {
                event.preventDefault();
                window.location.href = headerSearchForm.action;
            }
        });
    }

    // search ajaxified
    window.searchController = new SearchController(lang);

    // catalog component
    window.catalogBoxComponent = new CatalogBoxComponent(lang);

    // google maps
    window.googleMapsLoader = new GoogleMapsLoader({
        apiKey: 'AIzaSyBIvco0DL5gLDiKI_HpxxStYd9DijuDqVM',
        language: lang,
        region: 'CH'
    });
    window.googleMapsController = new GoogleMapsController();

    // content block expanders
    const contentBlockExpanders = document.getElementsByClassName('js-show-fullcontent');
    [].forEach.call(contentBlockExpanders, function (el: HTMLElement) {
        el.addEventListener('click', (event) => {
            const element = <HTMLElement>event.target;
            element.parentElement?.classList.add('expanded');
        });
    });

    // floating button
    window.addEventListener('scroll', debounce(() => {
        const elem = <HTMLElement>document.querySelector('.floating-btn');
        if (elem) {
            const scrollPos = 200;
            const windowY = window.scrollY;
            if (windowY > scrollPos) {
                elem.classList.add('is-visible');
                elem.classList.remove('is-hidden');
            } else {
                elem.classList.add('is-hidden');
                elem.classList.remove('is-visible');
            }
        }
    }, 300));

    // dispatch event initialization finished
    const initEvent = new CustomEvent('initialized', {});
    document.dispatchEvent(initEvent);
    window.isInitialized = true;
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}

// usercentrics consent management
window.ucDps = {
    googleMaps: false,
    youtubeVideo: false,
    captivatePodcast: false
};
window.addEventListener('onConsentStatusChange', (event: Event) => {
    const customEvent = <CustomEvent>event;

    const dpsGoogleMaps = 'Google Maps';
    const dpsYoutubeVideo = 'YouTube Video';
    const dpsCaptivate = 'Captivate';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (customEvent.detail.event && customEvent.detail.event === 'consent_status') {
        if (dpsGoogleMaps in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.googleMaps = !!customEvent.detail[dpsGoogleMaps];
        }
        if (dpsYoutubeVideo in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.youtubeVideo = !!customEvent.detail[dpsYoutubeVideo];
        }
        if (dpsCaptivate in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.captivatePodcast = !!customEvent.detail[dpsCaptivate];
        }

        const lang = window.siteHelper.getLanguage();
        // uses Google Maps, waiting for Usercentrics consent_status
        destinationSliderNavLoader(lang).then(() => null).catch(() => null);

        // may show YT videos, waiting for Usercentrics consent_status
        splideLoader().then(() => null).catch(() => null);

        // enable YT iframes
        const ytIframes = document.querySelectorAll('iframe.yt-video');
        if (ytIframes.length) {
            ytIframes.forEach((elem) => {
                const iFrameElem = <HTMLIFrameElement>elem;
                if (window.ucDps.youtubeVideo) {
                    if (iFrameElem.dataset.src) {
                        iFrameElem.src = iFrameElem.dataset.src;
                        iFrameElem.classList.remove('no-consent');
                    }
                } else {
                    iFrameElem.src = '';
                    iFrameElem.classList.add('no-consent');
                }
            });
        }

        const podcastIframes = document.querySelectorAll('iframe.captivate');
        if (podcastIframes.length) {
            podcastIframes.forEach((elem) => {
                const iFrameElem = <HTMLIFrameElement>elem;
                if (window.ucDps.captivatePodcast) {
                    if (iFrameElem.dataset.src) {
                        iFrameElem.src = iFrameElem.dataset.src;
                        iFrameElem.classList.remove('no-consent');
                    }
                } else {
                    iFrameElem.src = '';
                    iFrameElem.classList.add('no-consent');
                }
            });
        }

        // dispatch event
        const consentEvent = new CustomEvent('consentStatusSet', {});
        document.dispatchEvent(consentEvent);
    }
});
